import React from 'react'
import { Header } from '../header/Header'
import { ToastContainer } from 'react-toastify'
import './Dashboard.scss'

const Dashboard = () => {
  return (
    <div id='dashboard' className='dashboard'>
      <Header />
      <div className='information'>
        <div className='title'>
          <h2>LET'S</h2>
          <div className='middle-text'>AUTOMATE YOUR</div>
          <h2>BUSINESS</h2>
        </div>
      </div>
      <ToastContainer autoClose={14000} style={{ top: '6px' }} />
    </div>
  )
}

export default Dashboard
